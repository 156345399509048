.section-title-portarias {
  padding: 20px 0 20px 0;
  margin: 0 auto;
  text-align: center;
}

.search {
  display: flex;
}
.icone-download {
  margin-top: 7px;
  width: 30px;
}
.img-search-portarias {
  display: flex;
  border: 2px solid var(--cor-nome-portal);
  border-radius: 0px 15px 15px 0px;
  border-left: none;
  position: relative;
  right: -228px;
  display: flex;
  align-items: center;
  padding-right: 6px;
}
svg.icone-buscar-portarias {
  fill: var(--cor-icone-buscar-atos-administrativos);
}
svg.icone-download {
  fill: var(--cor-icone-buscar-atos-administrativos);
}
.icone-download:hover {
  transition: 1.5s;
  scale: 1.2;
}
.icone-buscar-portarias {
  width: 20px;
  margin-right: 5px;
}

.from-portarias {
  display: flex;
  align-items: stretch;
  position: relative;
  width: 15rem;
  height: 4rem;
}
input[type="text" i].form__input {
  position: absolute;
  top: 0;
  left: 0;
  width: 95%;
  height: 100%;
  font-size: 1.2rem;
  border: 2px solid var(--cor-nome-portal);
  border-radius: 15px 0px 0px 15px;
  font-family: inherit;
  font-size: inherit;
  color: rgb(0, 0, 0);
  outline: none;
  padding: 1.25rem;
  background: none;
  border-right: none;
}
label.form-label-portarias {
  font-size: 1.1rem !important;
  position: absolute;
  top: -0.7rem !important;
  left: 0.8rem !important;

  padding: 0 0.5rem;
  color: var(--cor-nome-portal);
  cursor: text;
  transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
  background-color: #ffff;
}

.box-download {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid var(--cor-nome-portal);
  border-radius: 15px;
  font-family: inherit;
  font-size: inherit;
  color: var(--cor-nome-portal);
  outline: none;
  padding: 1.25rem;
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

section.box {
  display: flex;
  justify-content: space-around;
}

section.table-show-portarias {
  max-width: 1201px;
  margin: 0 auto;
}
.ordinances-result {
  height: 7rem;
}

/* mudança boostrap */
.table-show-portarias .table > :not(caption) > * > * {
  text-align: center;
}
.portarias-td {
  padding: 22px !important;
}
td.title-color-ffff-table {
  padding: 41px 22px !important;
}
td.title-td-portais.portarias-td {
  text-align: justify;
  text-transform: uppercase;
}
td.title-td-portais.portarias-td a:hover {
  color: #0a58ca;
  color: black;
  scale: 1.1;
}

@media screen and (max-width: 670px) {
  h6.section-title {
    font-size: 0.8rem;
  }
  h6 {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 540px) {
  .from-portarias {
    width: 10rem;
    height: 3.2rem;
  }
  .img-search-portarias {
    right: -136px;
  }
  .icone-download {
    height: 26px;
    margin-top: 8px;
  }

  .portarias-td {
    padding: 6px !important;
  }
  td.title-td-portais.portarias-td {
    font-size: 0.9em;
  }
  td.acoes-td-portais.portarias-td {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  img.icone-download.list-portarias {
    width: 20px;
  }
}

.TableSacopInfor > :not(:first-child) {
  border-top: 1px solid currentColor;
}
table.TableSacopInfor {
  border-color: var(--cor-sub-lista-navbar);
}

.TableSacopInfor > thead {
  vertical-align: middle;
}

/*Paginação*/

.page-item.active .page-link {
  background-color: var(--cor-sub-lista-navbar) !important;
  border-color: #dee2e6 !important;
}
li a.page-link span {
  color: var(--cor-sub-lista-navbar) !important;
}
.page-link:focus {
  box-shadow: 0 0 0 0.25rem var(--cor-sub-lista-navbar) !important;
}
/*footer paginação table */
section.footer-pagination-table {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: var(--diary-table-txt);
  font-weight: 500;
}
.table-show-portarias.table-files-sacop .table > :not(caption) > * > * {
  text-align: initial;
}
table#files-sacop {
  text-transform: uppercase;
}
